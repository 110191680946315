

*{
  margin:0;
  padding:0;
  text-decoration: none;  
  /* font-family:   "Helvetica", "Arial", "Sans-Serif","Roboto", "Arial",
 "Georgia",
 "Impact",
 "Tahoma",
 "Times New Roman",
 "Verdana",
; */
 font-size: 12;
}
.App {
  text-align: center;
  height:100%;
  
}

body{
  font-family: 
  "Assistant",
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
   "Helvetica", "Arial", "Sans-Serif","Roboto", "Arial",
  "Georgia",
  "Impact",
  "Tahoma",
  "Times New Roman",
  "Verdana",
 ;

}
#root{
  height:100%;
}
.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.custom-link:hover {
  color: white;
  text-decoration-color: white;
  font-weight: 700;
  font-size:medium;
}
.inciddent-link{
  font-size: 10px;
}
.incident-link:hover{
  color:#2DCDDF;

  /* font-weight: 100; */
}
.editor-class {
  background-color:white;
  padding: 1rem;
  border: 1px solid #ccc;
   /* overflow-y: auto;
   overflow-x: auto; */
   /* height: 600px; */
}
div.editor-class.rdw-editor-main{
  height:200px; 
  max-height: 200px;
}
.toolbar-class {
  border: 1px solid #ccc;
}
div.rdw-editor-toolbar.toolbar-class{
  background: #F6F6F6;
}
.block {
  display: flex;
  margin-bottom: 24px;
  font-size: 14px;
  color: #545454;
  
}
.disable-pointer-events{
  pointer-events: none;
  opacity: 0.4;
}
.header{
  font-weight: 800;
}
.pages{
  text-align: left;
}
.greenIndicator{
  color:green
}
.yellowIndicator{
  color:rgb(225, 173, 1);
  size: 10;
}
header {
  margin-bottom: 16px;
  border-bottom: 1px solid #efefef;
}
body .login{

    background-image: url("../src/assets/body_bg.png");
    background-size: auto;
    width: 100%;
    height: 100vh;
    /* background-size: cover; */

}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body .status{
  background-image: url("https://www.data-axle.com/wp-content/uploads/2020/08/dots_left_right_white-2.svg");
   background-repeat: no-repeat;
   min-height: 900px;
   /* display: flex;   
   justify-content: center;
   align-items: center; */
  /* background-size: cover;  */
 
} 
body .status-logo{
  background-image: url("../src/assets/black-da.png");
  height:300px;
  width:500px;
  background-repeat: no-repeat;
  text-align: center;


}
.link{
  display:flex;
  padding:10px 15px;
  gap: 15px;
  color:#000;
  transition: all 0.5s;
}
.evtUrT{
  position: sticky;
}
.link:hover{
  /* //background: lightgray; */
  color:"#000";
  transition: all 0.5s;
}
.icon , .icon-text{
font-size: 15px;
}
.wrap{
  text-overflow:ellipsis;
    /* width: 250px; */
    white-space: nowrap;

    overflow: hidden;
}
.wrap:hover{
  /* /* text-overflow:clip; */
   width:auto; 
  /* white-space: normal; */
 
  /* word-break: break-all; */
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.nav{
  text-decoration: "none";
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
/* .active:active{
  background: red;
  color:#000;
} */
/* .active{
  background: lightblue;
  color:#000;
} */
.onhover :hover{
  background-color: lightblue;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
